import { ColorMode } from "@chakra-ui/react";
import { ApexOptions } from "apexcharts";

interface PieChartsConfigProps {
  theme: ColorMode;
  labels: string[];
  chartId: string;
  name: string;
}
const colorMap = {
}
enum ChartTheme {
  LIGHT_TEXT_COLOR = "#1E3047",
  DARK_TEXT_COLOR = "#D6E8FF",
}

export const pieChartsConfig = ({
  theme,
  labels,
  chartId,
  name,
}: PieChartsConfigProps): ApexOptions => ({
  colors: Object.values(colorMap).concat(
    [
      "#EC008C",
      "#0d213a",
      "#626f7f",
      "#3a5696",
      "#d8dbe1",
      "#ec008c",
      "#00aeef",
      "#b31c7e",
      "#2fb39d",
    ]),
  theme: {
    mode: theme,
  },
  plotOptions: {
    pie: {
      dataLabels: {
        offset: -1.6
      },
      donut: {
        size: '55%'
      },
    },
  },
  chart: {
    type: "pie",
    id: chartId,
    background: theme === "dark" ? "#152130" : "#FFFFFF",
    toolbar: {
      show: false,
      export: {
        csv: {
          filename: name,
        },
      },
    },
  },
  dataLabels : {
    enabled: false,
      style : {
        fontSize: '12px'
      },

  },
  labels,
  fill: {
    opacity: 1,
  },
  grid: {
    padding: {
      bottom: 0,
    },
  },
  stroke: {
    show: false,
  },
  tooltip: {
    theme,
    fillSeriesColor: false
  },
  legend: {
    position: "bottom",
    horizontalAlign: "center",
    itemMargin: {
      vertical: 0,
    },
    markers: {
      width: 12,
      height: 12,
    },
    labels: {
      colors:
        theme === "light"
          ? ChartTheme.LIGHT_TEXT_COLOR
          : ChartTheme.DARK_TEXT_COLOR,
    },
  },
});
