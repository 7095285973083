import { ColorMode } from "@chakra-ui/react";
import { ApexOptions } from "apexcharts";
import { FaSlidersH } from "react-icons/fa";

interface StackedColumnScoutMConfigsProps {
  theme: ColorMode;
  categories: string[];
  chartId: string;
  name: string;
  tickAmount?: number;
}
const colorMap = {
}
enum ChartTheme {
  LIGHT_TEXT_COLOR = "rgba(30, 48, 71, 0.6)",
  DARK_TEXT_COLOR = "rgba(214, 232, 255, 0.6)",
  LIGHT_GRID_LINE_COLOR = "#EAEAEB",
  DARK_GRID_LINE_COLOR = " #254166",
}

export const stackedColumnScoutMConfigs = ({
  theme,
  categories,
  chartId,
  name,
  tickAmount
}: StackedColumnScoutMConfigsProps): ApexOptions => ({
  colors: Object.values(colorMap).concat(
    [
      "#EC008C",
      "#e366ad",
      "#999999",
      "#414F6A",
      "#1E3159",
      "#00aeef",
      "#b31c7e",
      "#2fb39d",
    ]),
  theme: {
    mode: theme,
  },
  plotOptions: {
    bar: {
      horizontal: true,
      distributed: false,
      columnWidth: "100%",
      barHeight: '80%',
      dataLabels: {
        total:{
          enabled: false
        },
      }
    },
  },
  chart: {
    type: "bar",
    background: theme === "dark" ? "#152130" : "#FFFFFF",
    stacked: true,
    toolbar: {
      show: false,
      export: {
        csv: {
          filename: name,
        },
      },
    },
    zoom: {
      enabled: true,
    },
    id: chartId,
  },
  yaxis: {
    tickAmount: tickAmount || 5,
    labels: {
      show : true,
      maxWidth: 800,
      offsetX: -15, //remove padding
      style: {
        colors:
          theme === "light"
            ? ChartTheme.LIGHT_TEXT_COLOR
            : ChartTheme.DARK_TEXT_COLOR,
      },
    },
  },
  grid: {
    borderColor:
      theme === "light"
        ? ChartTheme.LIGHT_GRID_LINE_COLOR
        : ChartTheme.DARK_GRID_LINE_COLOR,
    padding: {
      left: -5,
    },
  },
  xaxis: {
    //   type: "datetime",
    labels: { 
      show : false,
      hideOverlappingLabels: false,
      style: {
        fontSize: '10px',
        colors:
          theme === "light"
            ? ChartTheme.LIGHT_TEXT_COLOR
            : ChartTheme.DARK_TEXT_COLOR,
      },
    },
    categories: [
      ['The speaker provided feedback and/or','answered questions appropriately']
      ,['This program increased my','confidence in using {BRAND}']
    ],
  },
  tooltip: {
    theme,
    shared: true,
    followCursor: true,
    intersect: false,
    x: {
      show: true,
      format: '',
      formatter: function(label, opts) { 
        let index = opts.dataPointIndex;
        let total = 0;
        
        for (let counter = 0; counter < opts.series.length; counter++) {
          total = opts.series[counter][index] + total;
        }

        return label+ " | Total: " + total  
      },
    },
  },
  legend: {
    position: "top",
    markers: {
      radius: 100,
      width: 12,
      height: 12,
    },
    labels: {
      colors: "#6594D1",
    },
  },
  fill: {
    opacity: 1,
  },
  dataLabels: {
    enabled: false,
    formatter: function (val) {
      return val.toLocaleString();
    },
  },
});
