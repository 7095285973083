import { ColorMode } from "@chakra-ui/react";
import { ApexOptions } from "apexcharts";

interface ClusteredColumnConfigsProps {
  theme: ColorMode;
  categories: string[];
  chartId: string;
  name: string;
  tickAmount?: number;
}

enum ChartTheme {
  LIGHT_TEXT_COLOR = "rgba(30, 48, 71, 0.6)",
  DARK_TEXT_COLOR = "rgba(214, 232, 255, 0.6)",
  LIGHT_GRID_LINE_COLOR = "#EAEAEB",
  DARK_GRID_LINE_COLOR = " #254166",
}

export const clusteredColumnConfigs = ({
  theme,
  categories,
  chartId,
  name,
  tickAmount
}: ClusteredColumnConfigsProps): ApexOptions => ({
  
  colors: [
    "#0d213a",
    "#626f7f",
  '#EC008C', '#FCBC00', '#E84E0F', '#00AEEF', '#2FB39D','#559AD0','#9EA0E0','#FF91D2','#92E1FF','#F9BDA6','#FFEA9B' 
  ],
    theme: {
    mode: theme,
  },
  plotOptions: {
    bar: {
      columnWidth: "90%",
      dataLabels: {
        total:{
          enabled: false
        },
      }
      
    },
  },
  chart: {
    type: "bar",
    background: theme === "dark" ? "#152130" : "#FFFFFF",
    stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: true,
    },
    id: chartId,
  },
  yaxis: {
    tickAmount: tickAmount || 5,
    labels: {
      offsetX: -15, //remove padding
      style: {
        colors:
          theme === "light"
            ? ChartTheme.LIGHT_TEXT_COLOR
            : ChartTheme.DARK_TEXT_COLOR,
      },
      formatter: function(val) {
        return "$"+val.toLocaleString()
    }
    },
  },
  grid: {
    borderColor:
      theme === "light"
        ? ChartTheme.LIGHT_GRID_LINE_COLOR
        : ChartTheme.DARK_GRID_LINE_COLOR,
    padding: {
      left: -5,
    },
  },

  xaxis: {
    //   type: "datetime",
    labels: {
      style: {
        fontSize: '11px',
        colors:
          theme === "light"
            ? ChartTheme.LIGHT_TEXT_COLOR
            : ChartTheme.DARK_TEXT_COLOR,
      },
    },
    categories,
  },
  tooltip: {
    shared: true,
    intersect: false,
    theme,
    x: {
      show: true,
      format: '',
      formatter: function(label, opts) { 
        let index = opts.dataPointIndex;
        let total = 0;
        
        for (let counter = 0; counter < opts.series.length; counter++) {
          total = opts.series[counter][index] + total;
        }

        return label+ " | Total: $" + total.toLocaleString()  
      },
    },
  },
  legend: {
    position: "bottom",
    markers: {
      radius: 100,
      width: 12,
      height: 12,
    },
    labels: {
      colors: "#6594D1",
    },
  },
  fill: {
    opacity: 1,
  },
  dataLabels: {
    enabled: false,
    formatter: function(val) {
      return "$"+val.toLocaleString()
  }},
});
