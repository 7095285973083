import { ColorMode } from "@chakra-ui/react";
import { ApexOptions } from "apexcharts";
import { table } from "console";
import { config } from "process";
import { GoogleChartOptions } from "react-google-charts";
import { ChartData } from "../@types/interfaces/app/dashboard-structure";
import { clusteredColumnConfigs } from "./charts/clustered-column";
import { donutChartsConfig } from "./charts/donut-chart";
import { scoutDonutChartsConfig } from "./charts/donut-chart-scout";
import { pieChartsConfig } from "./charts/pie-charts";
import { sankeyChartsConfig } from "./charts/sankey";
import { stackedBarConfigs } from "./charts/stacked-bar";
import { stackedColumnConfigs } from "./charts/stacked-column";
import { stackedColumnScoutConfigs } from "./charts/stacked-column-scout";
import { stackedColumnScoutMConfigs } from "./charts/stacked-column-scout-m";
import { tableChartsConfig } from "./charts/table";
import { dataGridConfig } from "./charts/data-grid";

const googleChartTypes = ["Sankey", "Table"];

export const getGoogleChartType = (chartData: ChartData) => {
  if (googleChartTypes.indexOf(chartData.chartType) > -1) {
    return true;
  } else {
    return false;
  }
};

const rawChartTypes = ["dataGrid"];

export const getRawDataType = (chartData: ChartData) => {
  if (rawChartTypes.indexOf(chartData.chartType) > -1) {
    return true;
  } else {
    return false;
  }
};

export const charts = {
  clusteredColumn: clusteredColumnConfigs,
  scoutDonutChart: scoutDonutChartsConfig,
  donutChart: donutChartsConfig,
  pieChart: pieChartsConfig,
  stackedColumn: stackedColumnConfigs,
  stackedColumnScout : stackedColumnScoutConfigs,
  stackedColumnScoutM : stackedColumnScoutMConfigs,
  stakedBar: stackedBarConfigs,
  Sankey: sankeyChartsConfig,
  Table: tableChartsConfig,
  dataGrid: dataGridConfig,
};

export const convertChartData = (
  data: { [key: string]: any },
  chartData: ChartData
) => {
  if (chartData.hasCategories) {
    try {
      const series = data[chartData.dataKey].data.flatMap((d: any) => ({
        name: d.name,
        data: d.Interactions,
      }));
      return series;
    } catch (e: any) {
      const series = data[chartData.dataKey].flatMap(
        (d: any) => d.Interactions
      );
      return series;
    }
  }

  if (chartData.hasLabels) {
    const series = data[chartData.dataKey].flatMap((d: any) => d.Interactions);
    return series;
  }

  if (chartData.chartType === "Sankey") {
    let series = Object.keys(data[chartData.dataKey]).map((index) => {
      let arr = data[chartData.dataKey][index];
      return arr;
    });

    series = series.map(Object.values);
    if (series.length === 0) {
      return [
        ["To", "From", "Weight"],
        ["To", 0, 0],
      ];
    } else {
      return [["To", "From", "Weight"]].concat(series);
    }
  }

  if (chartData.chartType === "dataGrid") {
    const series = data[chartData.dataKey];
    return series;
  }

  if (chartData.chartType === "Table") {
    let series = Object.keys(data[chartData.dataKey]).map((index) => {
      let arr = data[chartData.dataKey][index];
      return arr;
    });

    series = series.map(Object.values);

    return [
      ["KOL", "KOL Level", "Territory", "Specialty", "Name", "Interactions"],
    ].concat(series);
  }

  const series = data
    ? data[chartData.dataKey].flatMap((d: any) => ({
        name: d.name,
        data: d.Interactions,
      }))
    : [];
  return series;
};

export const generateChartConfig = (
  colorMode: ColorMode,
  chartData: ChartData,
  data: { [key: string]: any }
): ApexOptions | any => {
  let config: {
    theme: ColorMode;
    categories?: string[];
    labels?: string[];
    chartId: string;
    name: string;
    tickAmount?: number;
  } = { theme: colorMode, chartId: chartData.chartId, name: chartData.name, tickAmount: chartData.tickAmount };

  //for categories charts
  if (chartData.hasCategories) {
    config.categories = data[chartData.dataKey].categories;
  }

  //for donut charts
  if (chartData.hasLabels) {
    config.labels = data[chartData.dataKey].flatMap((d: any) => d.name);
  }

  if (getGoogleChartType(chartData)) {
    // if it is a google chart return this
    return charts[chartData.chartType](config as any);
  } else {
    // else this
    return charts[chartData.chartType](config as any);
  }
};

export const generateChartTableHeader = (config: ApexOptions) => {
  if (config.chart?.type === "donut" || config.chart?.type === "pie") {
    return config.labels;
  } else {
    return config.xaxis?.categories;
  }
};
