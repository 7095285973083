import { FiTag, FiUsers } from "react-icons/fi";
import { DashboardStructure } from "../@types/interfaces/app/dashboard-structure";
var structure : DashboardStructure[] = []
const view = localStorage.getItem("view")

if(view==='Scout') {
  structure = [
    {
      name: "Survey A",
      pages: [
        {
          name: "Page 1",
          templateArea: [
            `
              "surveyb_programs"
              "surveyb_programs_completed"
              "surveyb_responses"
              "surveyb_likert"
              "chartb1"
              "chartb2"
              "chartb3"
              "chartb4"
              "chartb5"

            `,
            null,
            `
              "surveyb_programs surveyb_programs_completed surveyb_responses surveyb_likert"
              'chartb1 chartb2 chartb3 chartb3'
              'chartb4 chartb4 chartb5 chartb5'
            `,
            `
              "surveyb_programs surveyb_programs_completed surveyb_responses surveyb_likert"
              'chartb1 chartb2 chartb3 chartb3'
              'chartb4 chartb4 chartb5 chartb5'
            `,
          ],
          KPIs: [
            {
              name: "Responses",
              KPIKey: "surveyb_responses",
              icon: FiUsers,
              color: "#626f7f",
            },
            {
              name: "Average Likert Response",
              KPIKey: "surveyb_likert",
              icon: FiTag,
              color: "#EC008C",
            },
            {
              name: "Programs Completed",
              KPIKey: "surveyb_programs",
              icon: FiTag,
              color: "#3A5696",
            },
            {
              name: "Programs with Evaluations Completed",
              KPIKey: "surveyb_programs_completed",
              icon: FiTag,
              color: "#FCBC00",
            },
          ],
          charts: [
            {
              name: "This program helped me feel more confident in identifying appropriate patients for {BRAND}",
              dataKey: "surveyb_question1",
              hasCategories: false,
              chartType: "pieChart",
              hasLabels: true,
              chartId: "chartb1"
            },
            {
              name: "I would recommend the program to colleagues.",
              dataKey: "surveyb_question2",
              hasCategories: false,
              chartType: "scoutDonutChart",
              hasLabels: true,
              chartId: "chartb2"
            },
            {
              name: "What is your practice setting?",
              dataKey: "surveyb_question3",
              hasCategories: true,
              chartType: "stackedColumn",
              hasLabels: false,
              chartId: "chartb3"
            },
            {
              name: "Indicate your role(s) (select all that apply)",
              dataKey: "surveyb_question4",
              hasCategories: true,
              chartType: "stackedColumn",
              hasLabels: false,
              chartId: "chartb4"
            },
            {
              name: "Likert Questions",
              dataKey: "surveyb_question5",
              hasCategories: true,
              chartType: "stackedColumnScoutM",
              hasLabels: false,
              chartId: "chartb5"
            },
          ],
        },
      ],
    },
    {
      name: "Survey B",
      pages: [
        {
          name: "Page 1",
          templateArea: [
            `
              "surveyc_programs"
              "surveyc_programs_completed"
              "surveyc_responses"
              "surveyc_likert"
              "chartc1"
              "chartc2"
              "chartc3"
              "chartc4"
              "chartc6"
            `,
            null,
            `
              "surveyc_programs surveyc_programs_completed surveyc_responses surveyc_likert"
              'chartc1 chartc3 chartc3 chartc3'
              'chartc1 chartc2 chartc6 chartc4' 
            `,
            `
              "surveyc_programs surveyc_programs_completed surveyc_responses surveyc_likert"
              'chartc1 chartc3 chartc3 chartc3'
              'chartc1 chartc2 chartc6 chartc4'    
            `,
          ],
          KPIs: [
            {
              name: "Responses",
              KPIKey: "surveyc_responses",
              icon: FiUsers,
              color: "#626f7f",
            },
            {
              name: "Average Likert Response",
              KPIKey: "surveyc_likert",
              icon: FiTag,
              color: "#EC008C",
            },
            {
              name: "Programs Completed",
              KPIKey: "surveyc_programs",
              icon: FiTag,
              color: "#3A5696",
            },
            {
              name: "Programs with Evaluations Completed",
              KPIKey: "surveyc_programs_completed",
              icon: FiTag,
              color: "#FCBC00",
            },
          ],
          charts: [
            {
              name: "What is your practice setting?",
              dataKey: "surveyc_question1",
              hasCategories: true,
              chartType: "stakedBar",
              hasLabels: false,
              chartId: "chartc1"
            },
            {
              name: "The speaker exhibited noteworthy subject matter expertise.",
              dataKey: "surveyc_question2",
              hasCategories: true,
              chartType: "stackedColumnScout",
              hasLabels: false,
              chartId: "chartc2"
            },
            {
              name: "Indicate your role(s) (select all that apply)",
              dataKey: "surveyc_question3",
              hasCategories: true,
              chartType: "stackedColumn",
              hasLabels: false,
              chartId: "chartc3"
            },
            {
              name: "This program offered relevant information for my role.",
              dataKey: "surveyc_question4",
              hasCategories: false,
              chartType: "scoutDonutChart",
              hasLabels: true,
              chartId: "chartc4"
            },
            {
              name: "This program increased my consideration of {PRODUCT} for appropriate patients",
              dataKey: "surveyc_question6",
              hasCategories: true,
              chartType: "stackedColumnScout",
              hasLabels: false,
              chartId: "chartc6"
            },

          ],
        },
      ],
    },
  ]
} else {
  structure = [
    {
      name: "Programs",
      pages: [
        {
          name: "Page 1",
          templateArea: [
            `
              "events_completed"
              "events_planned"
              "events_canceled"
              "event_attendees"
              "chart0"
              "chart2"
              "chart04"
              "chart03"
            `,
            null,
            `
              "events_completed events_planned events_canceled event_attendees"
              'chart0 chart0 chart0 chart04'
              'chart2 chart2 chart2 chart03'
            `,
            `
              "events_completed events_planned events_canceled event_attendees"
              'chart0 chart0 chart0 chart04'
              'chart2 chart2 chart2 chart03'
            `,
          ],
          KPIs: [
            {
              name: "Completed Programs",
              KPIKey: "events_completed",
              icon: FiTag,
              color: "#626f7f",
            },
            {
              name: "Programs in Planning",
              KPIKey: "events_planned",
              icon: FiTag,
              color: "#3a5696",
            },
            {
              name: "Cancelled Programs",
              KPIKey: "events_canceled",
              icon: FiTag,
              color: "#0d213a",
            },
            {
              name: "Attendees",
              KPIKey: "event_attendees",
              icon: FiUsers,
              color: "#EC008C",
            },
          ],
          charts: [
            {
              name: "Event Status Over Time",
              dataKey: "events_by_status_over_time",
              hasCategories: true,
              chartType: "stackedColumn",
              hasLabels: false,
              chartId: "chart0"
            },
            {
              name: "Event Status Over Service Type",
              dataKey: "events_by_status_over_service",
              hasCategories: true,
              chartType: "stackedColumn",
              hasLabels: false,
              chartId: "chart2",
            },
            {
              name: "Event Status",
              dataKey: "events_by_status",
              hasCategories: false,
              chartType: "donutChart",
              hasLabels: true,
              chartId: "chart04",
            },
            {
              name: "Event by Type",
              dataKey: "events_by_status_over_type",
              hasCategories: true,
              chartType: "stakedBar",
              hasLabels: false,
              chartId: "chart03",
            },
          ],
        },
        {
          name: "Page 2",
          templateArea: [
            `
              "events_completed"
              "events_planned"
              "events_canceled"
              "event_attendees"
              "chart01"
              "chart1"
              "chart02"
              "chart3"
            `,
            null,
            `
              "events_completed events_planned events_canceled event_attendees"
              'chart01 chart01 chart1 chart1'
              'chart02 chart02 chart3 chart3'
            `,
            `
              "events_completed events_planned events_canceled event_attendees"
              'chart01 chart01 chart1 chart1'
              'chart02 chart02 chart3 chart3'
            `,
          ],
          KPIs: [
            {
              name: "Completed Programs",
              KPIKey: "events_completed",
              icon: FiTag,
              color: "#626f7f",
            },
            {
              name: "Programs in Planning",
              KPIKey: "events_planned",
              icon: FiTag,
              color: "#3a5696",
            },
            {
              name: "Cancelled Programs",
              KPIKey: "events_canceled",
              icon: FiTag,
              color: "#0d213a",
            },
            {
              name: "Attendees",
              KPIKey: "event_attendees",
              icon: FiUsers,
              color: "#EC008C",
            },
          ],
          charts: [
            {
              name: "Event by Topic",
              dataKey: "events_by_status_over_topic",
              hasCategories: true,
              chartType: "stakedBar",
              hasLabels: false,
              chartId: "chart01",
            },
            {
              name: "Event Status",
              dataKey: "events_by_status",
              hasCategories: false,
              chartType: "donutChart",
              hasLabels: true,
              chartId: "chart1",
            },
            {
              name: "Event by Type",
              dataKey: "events_by_status_over_type",
              hasCategories: true,
              chartType: "stakedBar",
              hasLabels: false,
              chartId: "chart02",
            },
            {
              name: "Cancellation Rationale",
              dataKey: "events_by_cancellation_rationale",
              hasCategories: true,
              chartType: "stakedBar",
              hasLabels: false,
              chartId: "chart3",
            },
          ],
        },
      ],
    },
    {
      name: "Speakers",
      pages: [
        {
          name: "Page 1",
          templateArea: [
            `
              "chart31"
              "chart32"
            `,
            null,
            `
              'chart31 chart31 chart31 chart31'
              'chart32 chart32 chart32 chart32'
            `,
            `
            'chart31 chart31 chart31 chart31'
            'chart32 chart32 chart32 chart32'
            `,
          ],
          charts: [
            {
              name: "Event by Speaker",
              dataKey: "events_by_status_over_speaker",
              hasCategories: true,
              chartType: "stackedColumn",
              hasLabels: false,
              chartId: "chart31",
            },
            {
              name: "Honoraria Spend by Speaker",
              dataKey: "honoraria_by_speaker",
              hasCategories: true,
              chartType: "clusteredColumn",
              hasLabels: false,
              chartId: "chart32",
            },
          ],
        },
      ],
    },
    {
      name: "Regions",
      pages: [
        {
          name: "Page 1",
          templateArea: [
            `
              "events_completed"
              "events_planned"
              "events_canceled"
              "event_attendees"
              "chart21"
              "chart23"
            `,
            null,
            `
              "events_completed events_planned events_canceled event_attendees"
              'chart21 chart21 chart21 chart21'
              'chart23 chart23 chart23 chart23'
            `,
            ` 
              "events_completed events_planned events_canceled event_attendees"
              'chart21 chart21 chart21 chart21'
              'chart23 chart23 chart23 chart23'
            `,
          ],
          KPIs: [
            {
              name: "Completed Programs",
              KPIKey: "events_completed",
              icon: FiTag,
              color: "#626f7f",
            },
            {
              name: "Programs in Planning",
              KPIKey: "events_planned",
              icon: FiTag,
              color: "#3a5696",
            },
            {
              name: "Cancelled Programs",
              KPIKey: "events_canceled",
              icon: FiTag,
              color: "#0d213a",
            },
            {
              name: "Attendees",
              KPIKey: "event_attendees",
              icon: FiUsers,
              color: "#EC008C",
            },
          ],
          charts: [
            {
              name: "Event Status Over Region",
              dataKey: "events_by_status_over_region",
              hasCategories: true,
              chartType: "stackedColumn",
              hasLabels: false,
              chartId: "chart21",
            },
            {
              name: "Event Status Over Territory",
              dataKey: "events_by_status_over_territory",
              hasCategories: true,
              chartType: "stackedColumn",
              hasLabels: false,
              chartId: "chart23",
            },
          ],
        },
      ],
    },
    {
      name: "Attendees",
      pages: [
        {
          name: "Page 1",
          templateArea: [
            `
              "events_completed"
              "event_attendees"
              "chart5"
              "charta6"
              "charta7"
            `,
            null,
            `
              "events_completed events_completed event_attendees event_attendees"
              'chart5 chart5 chart5 chart5'
              'charta6 charta6 charta6 charta7'
            `,
            `
              "events_completed events_completed event_attendees event_attendees"
              'chart5 chart5 chart5 chart5'
              'charta6 charta6 charta6 charta7'
            `,
          ],
          KPIs: [
            {
              name: "Completed Programs",
              KPIKey: "events_completed",
              icon: FiTag,
              color: "#626f7f",
            },
            {
              name: "Attendees",
              KPIKey: "event_attendees",
              icon: FiUsers,
              color: "#EC008C",
            },
          ],
            charts: [
              {
                name: "Attendees Over Time",
                dataKey: "attendees_by_status_over_time",
                hasCategories: true,
                chartType: "stackedColumn",
                hasLabels: false,
                chartId: "chart5",
              },
              {
                name: "Attendees by Credentials",
                dataKey: "attendees_by_credentials",
                hasCategories: true,
                chartType: "stackedColumn",
                hasLabels: false,
                chartId: "charta6",
              },
  
              {
                name: "Attendees by Program Type",
                dataKey: "attendees_by_format",
                hasCategories: true,
                chartType: "stackedColumn",
                hasLabels: false,
                chartId: "charta7",
              },
            ],
        },
        {
          name: "Page 2",
          templateArea: [
            `
              "events_completed"
              "event_attendees"
              "chart8"
              "chart7"
            `,
            null,
            `
              "events_completed events_completed event_attendees event_attendees"
              'chart8 chart8 chart8 chart8'
              'chart7 chart7 chart7 chart7'
            `,
            `
              "events_completed events_completed event_attendees event_attendees"
              'chart8 chart8 chart8 chart8'
              'chart7 chart7 chart7 chart7'
            `,
          ],
          KPIs: [
            {
              name: "Completed Programs",
              KPIKey: "events_completed",
              icon: FiTag,
              color: "#626f7f",
            },
            {
              name: "Attendees",
              KPIKey: "event_attendees",
              icon: FiUsers,
              color: "#EC008C",
            },
          ],
            charts: [
              {
                name: "Attendees by Territory",
                dataKey: "attendees_by_territory",
                hasCategories: true,
                chartType: "stackedColumn",
                hasLabels: false,
                chartId: "chart7",
              },
              {
                name: "Attendees by Region",
                dataKey: "attendees_by_region",
                hasCategories: true,
                chartType: "stackedColumn",
                hasLabels: false,
                chartId: "chart8",
              },
            ],
        },
        {
          name: "Page 3",
          templateArea: [
            `
              "events_completed"
              "event_attendees"
              "charta9"
              "chartet"
              "chartey"
            `,
            null,
            `
              "events_completed events_completed event_attendees event_attendees"
              'charta9 charta9 charta9 charta9'
              'chartet chartet chartey chartey'
            `,
            `
              "events_completed events_completed event_attendees event_attendees"
              'charta9 charta9 charta9 charta9'
              'chartet chartet chartey chartey'
            `,
          ],
          KPIs: [
            {
              name: "Completed Programs",
              KPIKey: "events_completed",
              icon: FiTag,
              color: "#626f7f",
            },
            {
              name: "Attendees",
              KPIKey: "event_attendees",
              icon: FiUsers,
              color: "#EC008C",
            },
          ],
            charts: [
              {
                name: "Attendees by Program Speaker",
                dataKey: "attendees_by_speaker",
                hasCategories: true,
                chartType: "stackedColumn",
                hasLabels: false,
                chartId: "charta9",
              },
              {
                name: "Attendees by Program Type",
                dataKey: "attendees_by_type",
                hasCategories: true,
                chartType: "stakedBar",
                hasLabels: false,
                chartId: "chartet",
              },
              {
                name: "Attendees by Topic",
                dataKey: "attendees_by_topic",
                hasCategories: true,
                chartType: "stakedBar",
                hasLabels: false,
                chartId: "chartey",
              },
            ],
        },
        {
          name: "Page 4",
          templateArea: [
            `
              "chartdga"
            `,
            null,
            `
              'chartdga chartdga chartdga chartdga'
              'chartdga chartdga chartdga chartdga'
            `,
            `
              'chartdga chartdga chartdga chartdga'
              'chartdga chartdga chartdga chartdga'
            `,
          ],
            charts: [
              {
                name: "",
                dataKey: "data_grid_attendee",
                hasCategories: false,
                chartType: "dataGrid",
                hasLabels: false,
                chartId: "chartdga",
              },
            ],
        },
      ],
    },
    {
      name: "Data Table",
      pages: [
        {
          name: "Page 1",
          templateArea: [
            `
              "chartdg"
            `,
            null,
            `
              'chartdg chartdg chartdg chartdg'
              'chartdg chartdg chartdg chartdg'
            `,
            `
              'chartdg chartdg chartdg chartdg'
              'chartdg chartdg chartdg chartdg'
            `,
          ],
          charts: [
            {
              name: "",
              dataKey: "data_grid",
              hasCategories: false,
              chartType: "dataGrid",
              hasLabels: false,
              chartId: "chartdg",
            },
          ],
        },
      ],
    },
  ]
}


export const exampleStructure: DashboardStructure[] = structure ;
