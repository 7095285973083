import {
  Avatar,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { MdArrowDropDown } from "react-icons/md";
import { RiMoonLine } from "react-icons/ri";
import { FiLogOut } from "react-icons/fi";
import { RiBarChartBoxLine } from "react-icons/ri";

import { AuthContext } from "../../providers/AuthProvider";
import useConfig from "../../hooks/useConfig";

const HeaderProfileBox: React.FC = () => {
  const { ssoLogoutUrl } = useConfig();
  const { toggleColorMode, colorMode } = useColorMode();
  const view = localStorage.getItem("view")

  const viewChange = () => {
    const view = localStorage.getItem("view")
    if (view ==="Scout") {
      localStorage.setItem("view","SP");
    } else {
      localStorage.setItem("view","Scout");
    }
    window.location.reload();
  }

  const { user, userIsAdmin } = useContext(AuthContext);

  const cardBackground = useColorModeValue(
    "light.cardsBackground",
    "dark.cardsBackground"
  );

  const menuCardBackground = useColorModeValue(
    "light.menuCardBackground",
    "dark.menuCardBackground"
  );

  const menuIconColor = useColorModeValue(
    "light.headerProfileMenuIconColor",
    "dark.headerProfileMenuIconColor"
  );

  const headerMenuTextColor = useColorModeValue(
    "light.headerProfileMenuTextColor",
    "dark.headerProfileMenuTextColor"
  );

  const headerProfileMenuNameColor = useColorModeValue(
    "light.headerProfileMenuNameColor",
    "dark.headerProfileMenuNameColor"
  );

  // const headerProfileMenuEmailColor = useColorModeValue(
  //   "light.headerProfileMenuEmailColor",
  //   "dark.headerProfileMenuEmailColor"
  // );

  const menuCardShadow = useColorModeValue(
    "0px 34px 14px rgba(218, 0, 0, 0.01), 0px 19px 12px rgba(0, 0, 0, 0.03), 0px 9px 9px rgba(0, 0, 0, 0.05), 0px 2px 5px rgba(0, 0, 0, 0.06), 0px 0px 0px rgba(0, 0, 0, 0.06)",
    "none"
  );

  const handleLogout = () => window.open(ssoLogoutUrl, "_self");
 
  return (
    <Menu closeOnSelect={false} closeOnBlur>
      <MenuButton
        borderRadius="4px"
        bgColor={cardBackground}
        maxW="187px"
        w="full"
        px="12px"
        py="8px"
      >
        <Flex w="full" h="full" align="center">
          <Avatar boxSize="32px" size="sm" name={user as any} />
          <Text
            ml="10px"
            flex={1}
            fontSize="14px"
            isTruncated
            color={headerProfileMenuNameColor}
            display={["none", "block"]}
          >
            {user}
          </Text>
          <Icon as={MdArrowDropDown} fontSize="20px" />
        </Flex>
      </MenuButton>
      <MenuList
        bgColor={menuCardBackground}
        boxShadow={menuCardShadow}
        py="16px"
        borderRadius="6px"
        border="none"
        zIndex={"tooltip"}
      >
        {userIsAdmin && (
          <>
            <MenuItem as="a" href="/manage-users">
              Manage Users
            </MenuItem>
            <MenuItem as="a" href="/user-roles">
              User Roles
            </MenuItem>
          </>
        )}
        <MenuItem
          onClick={viewChange}
          color={menuIconColor}
          icon={<RiBarChartBoxLine fontSize="20px" />}
        >
          <Flex align="center" color={headerMenuTextColor}>
            Scout View{" "}
            <Switch
              ml="auto"
              colorScheme="global.primaryScheme"
              isChecked={view === "Scout"}
            />
          </Flex>
        </MenuItem>
        <MenuItem
          onClick={toggleColorMode}
          color={menuIconColor}
          icon={<RiMoonLine fontSize="20px" />}
        >
          <Flex align="center" color={headerMenuTextColor}>
            Dark Mode{" "}
            <Switch
              ml="auto"
              colorScheme="global.primaryScheme"
              isChecked={colorMode === "dark"}
            />
          </Flex>
        </MenuItem>
        <MenuItem
          onClick={handleLogout}
          color={menuIconColor}
          icon={<FiLogOut fontSize="20px" />}
        >
          <Flex color={headerMenuTextColor}>Log Out</Flex>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default HeaderProfileBox;
