import React from "react";
import DateInput from "../inputs/DateInput";
import shallow from "zustand/shallow";
import { useDashboardStore } from "../../stores/dashboard.store";
import {
  Button,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";

const DateRangeFilter: React.FC = () => {
  const [dateRange, changeDateRange] = useDashboardStore(
    (s) => [s.dateRange, s.changeDateRange],
    shallow
  );

  const startDate = new Date(2023,0,1)
  const endDate = new Date(2023,11,31)
  if (startDate.toDateString()!= dateRange[0].toDateString() || endDate.toDateString() != dateRange[1].toDateString())
  {
    return (
      <Flex gap="10px" zIndex={"banner"}>
        <Button
          h="48px"
          fontSize="12px"
          fontWeight="medium"
          //bgColor={cardBackground}
          //borderColor={cardsBorderColor}
          borderWidth="0px"
          //color="#E3242B"
          _hover={{}}
          _active={{}}
          onClick={() => changeDateRange([startDate, endDate]) }
          //ref="null"
        >
        Clear <br/>Date
        </Button>
        <DateInput
          selected={dateRange[0]}
          onChange={(v) => changeDateRange([v, dateRange[1]])}
        />
        <DateInput
          selected={dateRange[1]}
          onChange={(v) => changeDateRange([dateRange[0], v])}
        />
      </Flex>
    );
  }
  return (
    <Flex gap="10px" zIndex={"banner"}>
      <DateInput
        selected={dateRange[0]}
        onChange={(v) => changeDateRange([v, dateRange[1]])}
      />
      <DateInput
        selected={dateRange[1]}
        onChange={(v) => changeDateRange([dateRange[0], v])}
      />
    </Flex>
  );
    
  };

export default DateRangeFilter;
