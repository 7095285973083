import {
  useColorModeValue,
  FlexProps,
  MenuButton,
  Button,
  MenuList,
  Menu,
  Input,
  Flex,
  Text,
  VStack,
  Tag,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import CategoryFilterItem from "./CategoryFilterItem";
import CategoryItem from "./CategoryItem";
import FilterItem from "../../../@types/interfaces/app/filter-item.interface";
import { useDashboardStore } from "../../../stores/dashboard.store";
import shallow from "zustand/shallow";
import moment from "moment";
import { RelatedBox } from "react-related-box";

const CategoriesFilter: React.FC<FlexProps> = ({ ...rest }) => {
  const [filtersList, setFiltersList] = useState<FilterItem[]>([]);
  const [allItems, setAllItems] = useState<FilterItem[]>([]);
  const [filterOptions, setFilterOptions] = useState<string[]>([]);
  const [selectedFilterOption, setSelectedFilterOption] =
    useState<string>("All");
  const [selectedFilters, setSelectedFilters] = useState<FilterItem[]>([]);
  const [dashboardData, loadDashboardData, dateRange] = useDashboardStore(
    (state) => [state.dashboardsData, state.loadDashboardData, state.dateRange],
    shallow
  );

  const [typedString, setTypedString] = useState<string>("");
  const [topItem, setTopItem] = useState<any>("");

  const onChangeValue = (v: string) => {
    if (selectedFilterOption === "All") {
      const filteredItems = allItems.filter((i) =>
        i.propertyName.toLocaleLowerCase().startsWith(v.toLocaleLowerCase())
      );
      setTypedString(v);
      setFiltersList(filteredItems.slice(0, 5));
      setTopItem(filteredItems[0]);
    } else {
      const filteredItems = allItems.filter(
        (i) =>
          i.propertyName
            .toLocaleLowerCase()
            .startsWith(v.toLocaleLowerCase()) &&
          i.filterCategory === selectedFilterOption
      );
      setTypedString(v);
      setFiltersList(filteredItems);
      setTopItem(filteredItems[0]);
    }
  };

  const handleKeyPressDown = (e: React.KeyboardEvent<any>) => {
    if (e.key === "Enter") {
      setTypedString("");

      const topItemElement = document.getElementById(topItem.propertyName);
      if (topItemElement) {
        topItemElement.click();

        const inputElement = e.target as HTMLInputElement;
        inputElement.blur();
        inputElement.focus();
      }
    }
  };

  const clickRead = () => {
    if (filtersList.length === 0) {
      const filteredItems = allItems.filter((i) =>
        i.propertyName
          .toLocaleLowerCase()
          .startsWith(typedString.toLocaleLowerCase())
      );
      setFiltersList(filteredItems.slice(0, 5));
      setSelectedFilterOption(selectedFilterOption);
    } else {
      const filteredItems = allItems.filter(
        (i) =>
          i.propertyName
            .toLocaleLowerCase()
            .startsWith(typedString.toLocaleLowerCase()) &&
          i.filterCategory === selectedFilterOption
      );
      setFiltersList(filteredItems);
      setSelectedFilterOption(selectedFilterOption);
    }
  };
  useEffect(() => {
    if (dashboardData) {
      const filtersListData: FilterItem[] = [];

      for (const key of Object.keys(dashboardData["filter_options"])) {
        for (const data of dashboardData["filter_options"][key]) {
          filtersListData.push({
            chartName: key,
            filterCategory: key,
            propertyName: data,
          });
        }
      }

      setFilterOptions(Object.keys(dashboardData["filter_options"]));
      setAllItems(filtersListData);
    }
  }, [dashboardData]);

  const removeSelectedFilterItem = (index: number) => {
    const previousSelected = [...selectedFilters];

    loadDashboardData({
      process: "remove",
      filter: previousSelected[index].filterCategory,
      value: previousSelected[index].propertyName,
    });

    previousSelected.splice(index, 1);

    let startDateString = moment(dateRange[0]).format("YYYYMMDD");
    let endDateString = moment(dateRange[1]).format("YYYYMMDD");

    // loadDashboardData({
    //   process: "between",
    //   filter: "DateId",
    //   value: [startDateString, endDateString].join(","),
    // });

    setSelectedFilters(previousSelected);
  };

  const cardBackground = useColorModeValue(
    "light.cardsBackground",
    "dark.cardsBackground"
  );

  const menuCardBackground = useColorModeValue(
    "light.menuCardBackground",
    "dark.menuCardBackground"
  );

  const cardsBorderColor = useColorModeValue(
    "light.cardsBorderColor",
    "dark.cardsBorderColor"
  );

  const menuLateralBorderColor = useColorModeValue(
    "light.menuLateralBorderColor",
    "dark.menuLateralBorderColor"
  );

  const menuCardTitleColor = useColorModeValue(
    "light.menuCardTitleColor",
    "dark.menuCardTitleColor"
  );

  const menuCardShadow = useColorModeValue(
    "0px 34px 14px rgba(218, 0, 0, 0.01), 0px 19px 12px rgba(0, 0, 0, 0.03), 0px 9px 9px rgba(0, 0, 0, 0.05), 0px 2px 5px rgba(0, 0, 0, 0.06), 0px 0px 0px rgba(0, 0, 0, 0.06)",
    "none"
  );

  const filterTextColor = useColorModeValue(
    "light.filterTextColor",
    "dark.filterTextColor"
  );

  const filterPlaceholderColor = useColorModeValue(
    "light.filterPlaceholderColor",
    "dark.filterPlaceholderColor"
  );

  const selectedCategoryBg = useColorModeValue(
    "light.selectedCategoryBg",
    "dark.selectedCategoryBg"
  );

  const selectedCategoryTextScheme = useColorModeValue(
    "light.selectedCategoryTextScheme",
    "dark.selectedCategoryTextScheme"
  );

  const onSelectFilter = (filter: FilterItem) => {
    loadDashboardData({
      filter: filter.filterCategory,
      process: "and",
      value: filter.propertyName,
    });
    setSelectedFilters([...selectedFilters, filter]);
  };

  return (
    <Flex
      flex="1"
      align="center"
      minH="48px"
      maxW="500px"
      borderRadius="4px"
      fontWeight="normal"
      bgColor={cardBackground}
      borderColor={cardsBorderColor}
      borderWidth="1px"
    >
      <Menu>
        <MenuButton
          px="16px"
          borderRadius="0px"
          color={menuCardTitleColor}
          as={Button}
          _hover={{}}
          rightIcon={<IoMdArrowDropdown />}
          borderRightWidth="1px"
          borderColor={menuLateralBorderColor}
        >
          <Text fontWeight="normal" fontSize="14px">
            {selectedFilterOption.split("`").join("")}
          </Text>
        </MenuButton>
        <MenuList
          zIndex={"banner"}
          boxShadow={menuCardShadow}
          bgColor={menuCardBackground}
          border="none"
          py="16px"
        >
          <VStack w="full" spacing="8px">
            <CategoryFilterItem
              value="All"
              onClick={() => setSelectedFilterOption("All")}
            >
              All
            </CategoryFilterItem>
            {filterOptions.map((i) => (
              <CategoryFilterItem
                key={i}
                value={i}
                onClick={() => setSelectedFilterOption(i)}
              >
                {i}
              </CategoryFilterItem>
            ))}
          </VStack>
        </MenuList>
      </Menu>
      <Flex flex="1" py="8px" px="16px" gap="8px" wrap="wrap">
        {selectedFilters.length > 0 &&
          selectedFilters.map((s, i) => (
            <Tag
              key={i}
              variant="subtle"
              borderRadius="full"
              bg={selectedCategoryBg}
              colorScheme={selectedCategoryTextScheme}
              fontSize="14px"
              lineHeight="24px"
              fontWeight={500}
              minW="fit-content"
            >
              <TagLabel>{s.propertyName}</TagLabel>
              <TagCloseButton onClick={() => removeSelectedFilterItem(i)} />
            </Tag>
          ))}

        <RelatedBox clearOnSelect>
          <RelatedBox.InputText
            typeDelay={100}
            customInput={
              <Input
                type="text"
                flex="1"
                minW="200px"
                borderRadius="0px"
                placeholder="Search..."
                variant="unstyled"
                h="full"
                fontSize="14px"
                color={filterTextColor}
                value={typedString}
                _placeholder={{
                  color: filterPlaceholderColor,
                }}
                onKeyDown={handleKeyPressDown}
                onClick={clickRead}
              />
            }
            onChange={(value) => onChangeValue(value)}
          />
          <RelatedBox.SuggestionsBox
            data={filtersList}
            onSelectItem={onSelectFilter}
            renderItem={(i) => <CategoryItem {...i} />}
            customComponent={(items) => (
              <Flex
                flexDir="column"
                mt="14px"
                bg={menuCardBackground}
                borderColor={cardsBorderColor}
                borderWidth="1px"
                borderRadius="6px"
                boxShadow={menuCardShadow}
                w="100%"
              >
                <Text
                  ml="12px"
                  mt="12px"
                  fontWeight="semibold"
                  fontSize="14px"
                  textTransform="uppercase"
                  color={menuCardTitleColor}
                  mb="8px"
                >
                  Results
                </Text>
                <VStack spacing="12px" w="full">
                  {items}
                </VStack>
              </Flex>
            )}
          />
        </RelatedBox>
      </Flex>
    </Flex>
  );
};

export default CategoriesFilter;
