import axios from "axios";
import moment from "moment";
import create from "zustand";
import { devtools } from "zustand/middleware";
// import api from "../services/api";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  withCredentials: true,
  headers: {
    // "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
});

interface Filters {
  process: "and" | "like" | "between" | "in" | "clear" | "remove";
  filter: string;
  value: string;
}

interface DashboardStoreData {
  selectedDashboard: number;
  selectedDashboardPage: number;
  dashboardsData: { [key: string]: any } | undefined;
  dateRange: Date[];
  changeDateRange: (range: Date[]) => void;
  loadDashboardData: (filters?: Filters) => Promise<void>;
  setDashboardData: (data: { [key: string]: any }) => void;
  changeDashboardPage: (page: number) => void;
  changeDashboard: (page: number) => void;
}

export const useDashboardStore = create<DashboardStoreData>()(
  devtools(
    (set, get) => ({
      selectedDashboard: 0,
      selectedDashboardPage: 0,
      dashboardsData: undefined,
      dateRange: [new Date("2023/01/01"), new Date("2023/12/31")],
      changeDateRange: (dates) => {
        set({ dateRange: dates });

        const startDateString = moment(dates[0]).format("YYYYMMDD");
        const endDateString = moment(dates[1]).format("YYYYMMDD");

        get().loadDashboardData({
          process: "between",
          filter: "DateId",
          value: [startDateString, endDateString].join(","),
        });
      },
      loadDashboardData: async (filters) => {
        try {
          const { data } = await api.post("", undefined, { params: filters });
          set({ dashboardsData: data });
        } catch (error) {
          console.error(error);
        }
      },
      setDashboardData: (data) => set({ dashboardsData: data }),
      changeDashboardPage: (data) => set({ selectedDashboardPage: data }),
      changeDashboard: (data) =>
        set({ selectedDashboard: data, selectedDashboardPage: 0 }),
    }),
    { name: "Dashboard store" }
  )
);
